const initialCards = [
    {
        name: "Murr Market",
        desc: "Онлайн-магазин с упором на стабильность работы и легкость в расширении функционала. Основная цель проекта — разработка высококачественного продукта, соответствующего современным техническим стандартам.",
        tag: "Pet-проект",
        image: `${require("../images/murr-market.png")}`,
        linkSite: "/projects/murr-market/",
        linkGitHub: "https://github.com/Andrpre/murr-market",
        stack: ["React", "Html", "SCSS", "Git", "CRA"],
    },
    {
        name: "To-Do practic",
        desc: "Интерактивное веб-приложение для управления списками дел, созданное с акцентом на масштабируемость и лёгкую расширяемость функционала.",
        tag: "Pet-проект",
        image: `${require("../images/to-do-practic.png")}`,
        linkSite: "/projects/to-do-practic/",
        linkGitHub: "https://github.com/Andrpre/to-do-practic",
        stack: ["React", "Html", "CSS", "Git", "CRA"],
    },
    {
        name: "Stellar Burger",
        desc: "Веб-приложение для создания собственного бургера. Вы можете выбрать ингредиенты и оформить заказ, при этом приложение предоставляет удобный и интуитивно понятный интерфейс для пользователя.",
        tag: "Учебный",
        image: `${require("../images/stellar-burger.png")}`,
        linkSite: "/projects/stellar-burger/",
        linkGitHub: "https://github.com/Andrpre/stellar-burger",
        stack: [],
    },
    {
        name: "Web larek",
        desc: "Онлайн-магазин, реализованный с использованием ООП. В проекте применяется паттерн MVP, который разделяет бизнес-логику, интерфейс и управление данными, обеспечивая чистую архитектуру и легкость в тестировании.",
        tag: "Учебный",
        image: `${require("../images/web-larek.png")}`,
        linkSite: "/projects/web-larek/",
        linkGitHub: "https://github.com/Andrpre/web-larek-frontend",
        stack: [],
    },
    {
        name: "Место",
        desc: "Интерактивное веб-приложение, которое позволяет пользователям настраивать свой профиль, публиковать фотографиями различных мест, добавлять названия и ставить лайки.",
        tag: "Учебный",
        image: `${require("../images/mesto.png")}`,
        linkSite: "/projects/mesto-project-ff/",
        linkGitHub: "https://github.com/Andrpre/mesto-project-ff",
        stack: ["javaScript", "Html", "CSS", "Git", "Webpack"],
    },
    {
        name: "Закрывающий тег",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/zakrivayuschiy-teg-f.png")}`,
        linkSite: "/projects/zakrivayuschiy-teg-f/",
        linkGitHub: "https://github.com/Andrpre/zakrivayuschiy-teg-f",
        stack: [],
    },
    {
        name: "Сложно сосредоточиться",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. В проекте применяется адаптивная верстка и присутствует переключение тем (темная/светлая). Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/slozhno-sosredotochitsya.png")}`,
        linkSite: "/projects/slozhno-sosredotochitsya/",
        linkGitHub: "https://github.com/Andrpre/slozhno-sosredotochitsya",
        stack: [],
    },
    {
        name: "Карты подскажут",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. В проекте применяется адаптивная верстка и присутствует переключение тем (темная/светлая). Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/karty-podskazhut.png")}`,
        linkSite: "/projects/karty-podskazhut/",
        linkGitHub: "https://github.com/Andrpre/karty-podskazhut-main",
        stack: [],
    },
    {
        name: "Посмотри в окно",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/posmotri-v-okno.png")}`,
        linkSite: "/projects/posmotri-v-okno/",
        linkGitHub: "https://github.com/Andrpre/posmotri_v_okno",
        stack: [],
    },
    {
        name: "Нужно идеально",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/we-need-perfectly.png")}`,
        linkSite: "/projects/we-need-perfectly/",
        linkGitHub: "https://github.com/Andrpre/we-need-perfectly",
        stack: [],
    },
    {
        name: "Оно тебе надо",
        desc: "Данный проект представляет собой одностраничный сайт сверстанный по макету в figma. Проект прошел проверку соответствия по Pixel Perfect.",
        tag: "Учебный",
        image: `${require("../images/ono-tebe-nado.png")}`,
        linkSite: "/projects/ono-tebe-nado/",
        linkGitHub: "https://github.com/Andrpre/ono-tebe-nado",
        stack: [],
    },
];

export { initialCards };
